<template>
  <TransitionRoot appear as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 flex max-w-full pl-10">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl"
              >
                <div class="bb px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="pl-6 text-lg font-medium text-gray-900">
                      Edit Product
                    </DialogTitle>
                    <div class="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        class="focus:outline-none rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-indigo-500"
                        @click="onClose"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="relative mt-6 flex-1 px-4 sm:px-6"
                  v-loading="loading"
                >
                  <!-- Replace with your content -->
                  <!-- <form class="flex h-full flex-col bg-white"> -->
                  <el-form
                    ref="ruleFormRef"
                    :model="ruleForm"
                    :rules="rules"
                    label-width="auto"
                    status-icon
                    label-position="top"
                  >
                    <el-form-item label="Name" prop="name">
                      <el-input v-model="ruleForm.name" />
                    </el-form-item>
                    <el-form-item label="Description" prop="description">
                      <label for="description" class="sr-only"
                        >Description</label
                      >
                      <el-input
                        v-model="ruleForm.description"
                        type="textarea"
                      />
                    </el-form-item>
                    <el-form-item label="Price" prop="price">
                      <el-input v-model="ruleForm.price" />
                    </el-form-item>
                    <!-- <el-form-item
                      label="Recurring Billing"
                      class="flex flex-col"
                    >
                      <el-switch v-model="ruleForm.recurring" />
                    </el-form-item>
                    <el-form-item
                      v-if="ruleForm.recurring"
                      label="Recurring Period"
                      class="flex flex-col"
                      style="width: 100%"
                    >
                      <el-select
                        v-model="ruleForm.recurringInterval"
                        placeholder="Recurring Billing Interval"
                        style="width: 100%"
                      >
                        <el-option label="Send daily" value="day" />
                        <el-option label="Send weekly" value="week" />
                        <el-option label="Send monthly" value="month" />
                        <el-option label="Send yearly" value="year" />
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      v-if="ruleForm.recurring"
                      label="Recurring Interval Count"
                      class="flex flex-col"
                      style="width: 100%"
                    >
                      <el-input-number
                        v-model="ruleForm.recurringIntervalCount"
                        :min="1"
                        style="width: 100%"
                      />
                    </el-form-item> -->
                    <div class="flex flex-row space-x-4">
                      <button
                        type="button"
                        class="focus:outline-none w-1/2 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500"
                        @click="onClose"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        @click="submitForm(ruleFormRef)"
                        class="focus:outline-none ml-4 inline-flex w-1/2 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
                      >
                        Save
                      </button>
                    </div>
                  </el-form>
                  <!-- </form> -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, reactive } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";

import useProductsRepository from "@/composable/useProductsRepository";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  props: ["product"],
  emits: ["onClose", "onSave"],
  setup(props, { emit }) {
    const open = ref(true);
    const { loading, updateProduct } = useProductsRepository();

    const ruleForm = reactive({
      id: props.product?.id,
      name: props.product?.name,
      description: props.product?.description,
      price: Number(props.product?.price),
      recurring: props.product?.recurring,
      recurringInterval: props.product?.recurringInterval,
      recurringIntervalCount: props.product?.recurringIntervalCount,
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate(async (valid) => {
        if (valid) {
          let features = undefined;

          if (ruleForm.recurring) {
            features = [
              {
                name: "Recurring Billing",
                featureOptions: [
                  {
                    name: ruleForm.recurringInterval,
                  },
                  {
                    name: ruleForm.recurringIntervalCount,
                  },
                ],
              },
            ];
          }

          await updateProduct({
            id: props.product?.id,
            name: ruleForm.name,
            description: ruleForm.description,
            price: ruleForm.price,
            features,
          });

          emit("onClose", true);
          emit("onSave", true);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    };

    const resetForm = (formEl) => {
      if (!formEl) return;
      formEl.resetFields();
    };

    const onClose = () => {
      emit("onClose", true);
    };

    const ruleFormRef = ref();

    const rules = reactive({
      name: [{ required: true, message: "Please input name", trigger: "blur" }],
      description: [
        {
          required: true,
          message: "Please input description",
          trigger: "blur",
        },
      ],
      price: [
        { required: true, message: "Please input price", trigger: "blur" },
      ],
    });

    return {
      open,
      loading,
      onClose,
      ruleForm,
      submitForm,
      resetForm,
      ruleFormRef,
      rules,
    };
  },
};
</script>

<style scoped>
.el-form--label-top .el-form-item.flex {
  display: flex;
}
</style>
