<template>
  <div class="layout">
    <Title name="Items" />
    <div
      class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900">Items</h3>
      <div class="flex">
        <Search v-model:search="pagination.search" placeholder="Find items" />
        <button
          type="button"
          @click="openCreatePanel"
          class="order-0 focus:outline-none inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:ring-purple-500 sm:order-1 sm:ml-3"
        >
          Create Item
        </button>
      </div>
    </div>

    <div class="flex flex-col" v-loading="loading" v-if="products.length > 0">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div
            class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Desc
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody
                class="divide-y divide-gray-200 bg-white"
                v-if="products.length > 0"
              >
                <tr v-for="product in products" :key="product.Id">
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="items-left flex max-w-sm truncate">
                      <div class="text-sm font-medium text-gray-900">
                        {{ product.Name }}
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="text-sm text-gray-900">
                      ${{ Number(product.Price).toFixed(2) }}
                      <span
                        v-if="product?.ProductFeatures?.length > 0"
                        class="flex flex-row items-center space-x-1"
                        v-tippy="{
                          content: 'Recurring Product',
                          animation: 'scale',
                          animateFill: false,
                          theme: 'tomato',
                        }"
                      >
                        <RefreshIcon class="h-4 w-4 text-gray-400" />
                        <span class="mx-1"> Every </span>
                        <span
                          v-for="featureOption in product?.ProductFeatures[0]?.FeatureOptions?.reverse()"
                          :key="featureOption.Name"
                        >
                          {{ featureOption.Name }}
                        </span>
                      </span>
                    </div>
                  </td>
                  <td
                    class="max-w-sm truncate whitespace-nowrap px-6 py-4 text-left"
                  >
                    <div class="text-sm text-gray-900">
                      {{ product.Description }}
                    </div>
                  </td>

                  <td
                    class="flex space-x-3 whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                  >
                    <button
                      type="button"
                      v-tippy="{
                        content: 'Edit',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      @click="setEditModal(product)"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-purple-500"
                    >
                      <PencilIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
                    </button>
                    <button
                      type="button"
                      v-tippy="{
                        content: 'Archive',
                        animation: 'scale',
                        animateFill: false,
                        theme: 'tomato',
                      }"
                      @click="handleDelete(product.Id)"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-red-400 shadow-sm hover:text-red-800 focus:ring-red-500"
                    >
                      <ArchiveIcon class="h-5 w-5 flex-shrink-0 text-red-400" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination :pagination="pagination" @changePage="onChangePage" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-empty
        description="Oops, no items found."
        :image-size="200"
      ></el-empty>
    </div>

    <CreateNewProduct
      :show="open"
      @onCreateProduct="productCreated"
      @onClose="open = false"
    />

    <!-- Edit Product Form -->

    <EditProduct
      v-if="toggleEditModal"
      :show="toggleEditModal"
      :product="edit"
      @onClose="toggleEditModal = false"
      @onSave="saveEditForm"
    />

    <div
      aria-live="assertive"
      class="pointer-events-none fixed inset-0 z-20 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="show"
            class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <CheckCircleIcon
                    class="h-6 w-6 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-left text-sm font-medium text-gray-900">
                    Successfully saved!
                  </p>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                  <button
                    @click="show = false"
                    class="focus:outline-none inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-indigo-500"
                  >
                    <span class="sr-only">Close</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <!-- Modal -->
    <TransitionRoot as="template" :show="toggleModal">
      <Dialog
        as="div"
        static
        class="fixed inset-0 z-10 overflow-y-auto"
        @close="toggleModal = false"
        :open="toggleModal"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
            >
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                >
                  <ExclamationIcon
                    class="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6 text-gray-900"
                  >
                    Archive product
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Are you sure you want to archive this product?
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  @click="deleteProduct"
                >
                  Remove
                </button>
                <button
                  type="button"
                  class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  @click="toggleModal = false"
                  ref="cancelButtonRef"
                >
                  Cancel
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { reactive, ref, onMounted, watch } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import {
  XIcon,
  ArchiveIcon,
  PencilIcon,
  CheckCircleIcon,
  ExclamationIcon,
  RefreshIcon,
} from "@heroicons/vue/outline";

import Pagination from "@/common/components/pagination";
import Search from "@/common/components/search";
import CreateNewProduct from "@/views/invoice/details/new/items/_newProduct.vue";
import EditProduct from "../items/_edit.vue";

import useProductsRepository from "@/composable/useProductsRepository";

import { directive } from "vue-tippy";
import "tippy.js/animations/scale.css";

import API from "@/api";

export default {
  components: {
    Pagination,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    ArchiveIcon,
    PencilIcon,
    Search,
    CheckCircleIcon,
    ExclamationIcon,
    CreateNewProduct,
    EditProduct,
    RefreshIcon,
  },
  directives: {
    tippy: directive,
  },
  setup() {
    const open = ref(false);
    const show = ref(false);
    const toggleModal = ref(false);
    const toggleEditModal = ref(false);
    const featureOptionsName = ref("");
    const selectedProduct = ref(0);
    const selectedProductName = ref("");
    const { loading, products, getProducts, totalResults } =
      useProductsRepository();

    const pagination = reactive({
      page: 1,
      pageSize: 10,
      search: "",
      totalResults: totalResults,
    });

    const create = reactive({
      name: "",
      description: "",
      price: null,
      locations: [],
    });

    const edit = reactive({
      id: 0,
      name: "",
      description: "",
      price: null,
      recurring: false,
      recurringInterval: "",
      recurringIntervalCount: 0,
    });

    const onChangePage = (page) => {
      pagination.page = page;
      getProducts(pagination.page, pagination.pageSize, pagination.search);
    };

    const saveCreateForm = () => {
      open.value = false;

      API.Products.Products.save(create).then(() => {
        open.value = false;
        getProducts(pagination.page, pagination.pageSize, pagination.search);
        show.value = true;
      });
    };

    onMounted(() => {
      getProducts(pagination.page, pagination.pageSize, pagination.search);
    });

    const openCreatePanel = () => {
      open.value = true;
    };

    const handleDelete = (id) => {
      selectedProduct.value = id;
      toggleModal.value = true;
    };

    const deleteProduct = async () => {
      toggleModal.value = false;

      try {
        await API.Products.Products.remove(selectedProduct.value);
      } catch (error) {
        console.log({ error });
      } finally {
        getProducts(pagination.page, pagination.pageSize, pagination.search);
      }
    };

    const setEditModal = (product) => {
      console.log({ product });
      edit.id = product.Id;
      edit.name = product.Name;
      edit.description = product.Description;
      edit.price = product.Price;
      edit.recurring = product.ProductFeatures.some(
        (feature) => feature.Name === "Recurring Billing"
      );
      edit.recurringInterval =
        product.ProductFeatures[0]?.FeatureOptions[1]?.Name;
      edit.recurringIntervalCount =
        product.ProductFeatures[0]?.FeatureOptions[0]?.Name;
      toggleEditModal.value = true;
    };

    const saveEditForm = () => {
      getProducts(pagination.page, pagination.pageSize, pagination.search);
    };

    const productCreated = () => {
      open.value = false;
      getProducts(pagination.page, pagination.pageSize, pagination.search);
    };

    watch(
      () => pagination.search,
      (search) => {
        getProducts(1, 10, search);
      },
      { deep: true }
    );

    return {
      loading,
      open,
      show,
      create,
      edit,
      products,
      pagination,
      getProducts,
      onChangePage,
      saveCreateForm,
      openCreatePanel,
      toggleModal,
      handleDelete,
      deleteProduct,
      selectedProductName,
      featureOptionsName,
      toggleEditModal,
      setEditModal,
      saveEditForm,
      productCreated,
    };
  },
};
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  /* overflow-y: scroll; */
  width: 100%;
  position: relative;
}
</style>
